import * as React from "react";
import PersonalLayout from "../../../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../../../components/pages/personal/PersonalBody";
import {Button, message, Modal, Space} from "antd";
import OptionCard from "../../../../components/cards/optionCard";
import PersonalAddCard from "../../../../components/pages/personal/PersonalAddCard";
import Seo from "../../../../components/seo";
import {Link, navigate} from "gatsby";
import auth from "../../../../utils/auth";
import {useEffect, useState} from "react";
import jsonRequest from "../../../../utils/request/jsonRequest";
import {useSelector} from "react-redux";
import * as Moment from "moment"

const PersonalEduction = () => {
  auth.authorize()
  const {account} = useSelector((s: any) => s)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const load = () => {
    if (loading) {
      return;
    }
    setLoading(true)
    jsonRequest("/account/education").then(rsp => {
      if (rsp.success) {
        setData(rsp.data.data.map(item => ({
          ...item,
          startTime: Moment(item.startTime),
          endTime: item.endTime ? Moment(item.endTime) : null,
        })))
      } else {
        message.error(rsp.message)
      }
    }).catch(console.log).finally(() => {
      setLoading(false)
    })
  }

  const handleDestroy = (id: any) => {
    Modal.confirm({
      title: '确定删除?',
      okText: '删除',
      cancelText: '取消',
      onOk: () => {
        return jsonRequest("/account/education", {
          method: 'DELETE',
          data: {id}
        }).then(rsp => {
          if (rsp.success) {
            message.success('删除成功')
            load()
          } else {
            message.error(rsp.message)
          }
        }).catch(console.log)
      }
    })
  }

  useEffect(() => {
    if (account) {
      load()
    }
  }, [account])

  return (<>
    <PersonalLayout selected='resume' loading={loading}>
      <PersonalBody
        title='学习经历'
        backLink='/personal/resume'
      >
        <Space
          size={16}
          direction='vertical'
          style={{width: '100%'}}
        >
          {data?.map(item => (
            <OptionCard
              key={item.id}
              title={item.school}
              titleAppend={`${item.major}·${item.degree}`}
              desc={`${item.startTime.format("YYYY-MM-DD")} - ${item.endTime?.format("YYYY-MM-DD") ?? '至今'}`}
              options={(
                <Space size={8}>
                  <Link to={`/personal/resume/education/edit?id=${item.id}`}><Button>编辑</Button></Link>
                  <Button type='primary' danger onClick={() => handleDestroy(item.id)}>删除</Button>
                </Space>
              )}
            />
          ))}
          <PersonalAddCard
            text='添加经历'
            onClick={() => navigate('/personal/resume/education/add')}
          />
        </Space>
      </PersonalBody>
    </PersonalLayout>
  </>)
}

export default PersonalEduction

export const Head = () => <Seo />
